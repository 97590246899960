input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px #011f35 inset !important;
}

.section-title {
  font-family: 'Montserrat Extra Bold', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  margin: 0;
  padding-bottom: 21px;

  &.bordered {
    border-bottom: 1px solid #fff;
  }

  @media screen and (max-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 16px;
    margin-bottom: 13px;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 16px;
    margin-bottom: 13px;
  }
}

.q-btn {
  font-family: 'Montserrat Bold', sans-serif;
  border-radius: 10px;
  text-transform: none;
  font-size: 18px;
  padding: 12px 30px;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    padding: 1px 14px;
    font-size: 14px;
    border-radius: 5px;
  }
}

.q-field--dense .q-field__control, .q-field--dense .q-field__marginal {
  height: 54px;
  border-radius: 10px;

  @media screen and (max-width: 767px) {
    height: 34px;
    border-radius: 5px;
  }
}

.q-field--dense .q-field__label {
  top: 16px;

  @media screen and (max-width: 767px) {
    top: 6px;
  }
}
